<template>
  <loading v-if="loading" />
  <no-results
    v-else-if="!orderedEvents.length"
    title="No results"
    message="There are no entries to show."
  />
  <div v-else class="is-stretched">
    <slot name="header" :loading-site-changes="loadingSiteChanges" />
    <task-event
      v-for="event in orderedEvents"
      :key="`${event.type}-${event.id}`"
      v-bind="event.type === 'post' ? event : event.props"
      :task="task"
    >
      <site-change-post
        v-if="event.type === 'sitechange'"
        :date-created="event.dateCreated"
        :changes="changes[event.index].changes"
      />
      <task-post
        v-else
        v-bind="event"
        :task="task"
        @delete="deletePost(event.id)"
        @onEdit="onEdit"
      />
    </task-event>

    <load-more
      v-if="!pagination.complete"
      :loading="pagination.loading"
      @loadMore="loadMore"
    />
  </div>
</template>

<script>
import { events as logEvents } from "@src/services/log";
import { mapChanges } from "@src/services/siteChange";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where
} from "@firebase/firestore";

export default {
  name: "Posts",
  components: {
    "task-event": () => import("@shared/tasks/posts/_taskEvent"),
    "task-post": () => import("@shared/tasks/posts/_taskPost"),
    "site-change-post": () => import("@shared/tasks/posts/_siteChangePost")
  },
  props: {
    taskId: {
      type: String,
      required: true
    },
    includeMessages: {
      type: Boolean,
      default: true
    },
    includePrivate: {
      type: Boolean,
      default: null
    },
    includeInternal: {
      type: Boolean,
      default: null
    },
    includeFiles: {
      type: Boolean,
      default: null
    },
    includeLogs: {
      type: Boolean,
      default: true
    },
    includeSiteChanges: {
      type: Boolean,
      default: null
    },
    paginateSiteChanges: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      logs: {},
      posts: {},
      loading: true,
      loadingSiteChanges: false,
      oldestSiteChange: null,
      pagination: {
        limit: 15,
        loading: false,
        complete: true
      },
      observing: {}
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    },
    taskRef() {
      return doc(this.$firestore, "tasks", this.taskId);
    },
    siteChanges() {
      return this.$_.orderBy(
        this.$store.getters["sites/siteChanges"](this.task.siteId),
        ["timestamp"],
        ["desc"]
      );
    },
    orderedEvents() {
      const messages = this.messages.map((post, index) => ({
        ...post,
        type: "post",
        index
      }));

      return this.$_.orderBy(
        [...messages, ...this.changes],
        ["dateCreated"],
        ["desc"]
      );
    },
    changes() {
      return this.$_.toArray(mapChanges(this.siteChanges))
        .map((i, index) => {
          return {
            type: "sitechange",
            id: i[0].changeId,
            index: index,
            changeId: i[0].changeId,
            siteId: this.task.siteId,
            dateCreated: i[0].timestamp,
            changes: i,
            props: {
              authorId: "system",
              dateCreated: i[0].timestamp,
              isSiteChange: true
            }
          };
        })
        .filter(
          i =>
            !this.oldestPost ||
            i.dateCreated >= this.oldestPost.data().dateCreated.toDate()
        );
    },
    messages() {
      return this.$_({})
        .merge(this.posts, this.logs)
        .filter(post => {
          // Omit 'isPending' posts, unless author
          return (
            !post.data().isPending ||
            (post.data().isPending && post.data().authorId === this.userId)
          );
        })
        .filter(post => {
          // Omit logs older than oldest post
          return (
            !this.oldestPost ||
            post.data().dateCreated.toDate() >=
              this.oldestPost.data().dateCreated.toDate()
          );
        })
        .map(post => {
          const payload = {};
          if (post.ref.parent.id === "logs") {
            const log = post.data();
            payload["message"] = this.$_.get(logEvents, log.event, () => {
              return { text: log.event };
            })(log.metadata, log.request).text;
          }
          return this.$_.merge(
            {},
            {
              id: post.id,
              ...this.mapToTaskPost(post.data()),
              isLog: post.ref.parent.id === "logs"
            },
            payload
          );
        })
        .orderBy(
          [post => post.dateCreated, post => post.isFile],
          ["desc", "desc"]
        )
        .value();
    },
    newestPost() {
      return (
        this.$_(this.posts)
          .orderBy([post => post.data().dateCreated], ["desc"])
          .first() || null
      );
    },
    oldestPost() {
      return (
        this.$_(this.posts)
          .orderBy([post => post.data().dateCreated], ["desc"])
          .last() || null
      );
    },
    postsRef() {
      let ref = query(
        collection(this.$firestore, `tasks/${this.taskId}/posts`),
        where(`isHidden`, `==`, false)
      );
      if (this.$_.isBoolean(this.includePrivate))
        ref = query(ref, where(`isPrivate`, `==`, this.includePrivate));
      if (this.$_.isBoolean(this.includeInternal))
        ref = query(ref, where(`isInternal`, `==`, this.includeInternal));
      if (this.$_.isBoolean(this.includeFiles))
        ref = query(ref, where(`isFile`, `==`, this.includeFiles));
      return ref;
    }
  },
  watch: {
    includeSiteChanges() {
      if (this.includeSiteChanges) {
        this.initSiteChanges();
      } else {
        this.unobserveSiteChanges();
      }
    }
  },
  async mounted() {
    await this.getPosts();
    await this.observeLogs();
    await this.initSiteChanges();
    this.loading = false;
    this.observePosts();
    this.$bus.$on("post-updated", this.onPostUpdated);
  },
  beforeDestroy() {
    this.$bus.$off("post-updated", this.onPostUpdated);
    this.$_.each(this.observing, unsubscribe => {
      if (this.$_.isFunction(unsubscribe)) unsubscribe();
    });
  },
  methods: {
    onPostUpdated(snapshot) {
      if (this.posts[snapshot.id]) this.posts[snapshot.id] = snapshot;
    },
    onEdit(post) {
      this.$set(this.posts, post.id, post);
    },
    mapToTaskPost(post) {
      return {
        message: post.body,
        authorId: post.authorId,
        dateCreated: post.dateCreated.toDate(),
        isFile: post.isFile || false,
        fileData: post.file || null,
        fileThumbnail: post.fileThumbnail || null,
        isNote: post.isInternal || false,
        isPrivate: post.isPrivate || false,
        isPending: post.isPending || false,
        isPinned: post.isPinned || false
      };
    },
    async initSiteChanges() {
      if (!this.includeSiteChanges || !this.task.siteId)
        return Promise.resolve();

      // For changes mixed with posts get all between first and last post
      if (this.includeMessages && this.oldestPost && this.newestPost) {
        this.getSiteChanges({
          dateFrom: this.oldestPost.data().dateCreated.toDate(),
          dateUntil: this.newestPost.data().dateCreated.toDate()
        });
      }

      if (this.paginateSiteChanges) {
        await this.getSiteChangesPaginated();
      }

      /**
       * We observe new changes regardless if showing All tab or Changes tab but from different timestamps
       * If task is closed observe changes until the date it was closed(dateUpdated).
       * If task is open observe from latest post if there are posts
       * If paginateSiteChanges = TRUE start observing from NOW
       */
      const dateFrom =
        this.task.status >= "1-"
          ? this.paginateSiteChanges || !this.newestPost
            ? new Date()
            : this.newestPost.data().dateCreated.toDate()
          : this.task.dateUpdated;

      this.observeSiteChanges({ dateFrom });
    },
    async loadMore() {
      /** Get paginated by page size defined by pagination.limit */
      if (this.paginateSiteChanges) return this.getSiteChangesPaginated();

      // Else get paginated with limit defined by the oldest poost
      const prevOldestPostDate = this.oldestPost.data().dateCreated.toDate(); // Save oldest post
      await this.getPosts(); // Get posts
      const currentOldestPost = this.oldestPost.data().dateCreated.toDate(); // Current oldest post is new
      // Get changes between previous and the new oldest post
      this.getSiteChanges({
        dateFrom: currentOldestPost,
        dateUntil: prevOldestPostDate
      });
    },
    getPosts() {
      if (!this.includeMessages) return Promise.resolve();
      this.pagination.loading = true;
      const payload = {
        ref: query(this.postsRef, orderBy(`dateCreated`, `desc`)),
        limit: this.pagination.limit,
        cursor: this.oldestPost
      };

      return this.$store
        .dispatch("pagination/getPaginated", payload, {
          root: true
        })
        .then(({ results, complete }) => {
          this.$set(
            this,
            "posts",
            this.$_.merge(
              {},
              this.posts,
              this.$_.keyBy(results, post => post.id)
            )
          );
          this.pagination.complete = complete;
        })
        .catch(error => {
          console.error(error.message);
          this.$toast.open({
            message: "Error retrieving posts",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },
    observePosts() {
      if (!this.includeMessages) return Promise.resolve();
      const postsRef = query(
        this.postsRef,
        orderBy(`dateCreated`, `asc`),
        startAfter(this.newestPost)
      );
      return new Promise(resolve => {
        this.$set(
          this.observing,
          `posts`,
          onSnapshot(postsRef, { includeMetadataChanges: true }, snapshot => {
            this.$_.each(snapshot.docChanges(), change => {
              const post = change.doc;
              if (!post.metadata.hasPendingWrites) {
                if (change.type === "removed") {
                  this.$delete(this.posts, post.id);
                } else {
                  this.$set(this.posts, post.id, post);
                }
              }
              resolve();
            });
          })
        );
      });
    },
    deletePost(postId) {
      this.$store
        .dispatch("tasks/deletePost", {
          taskId: this.taskId,
          postId
        })
        .then(() => {
          this.$delete(this.posts, postId);
          this.$toast.open({
            message: "Message deleted"
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "Error deleting message",
            type: "is-danger"
          });
        });
    },
    observeLogs() {
      if (!this.includeLogs) return Promise.resolve();
      const logsRef = query(
        collection(this.$firestore, "logs"),
        where("metadata.taskRef", "==", this.taskRef)
      );
      return new Promise(resolve => {
        this.$set(
          this.observing,
          `logs`,
          onSnapshot(logsRef, { includeMetadataChanges: true }, snapshot => {
            if (!snapshot.metadata.hasPendingWrites) {
              this.$_.each(snapshot.docChanges(), change => {
                const log = change.doc;
                if (change.type === "removed") {
                  this.$delete(this.logs, log.id);
                } else {
                  this.$set(this.logs, log.id, log);
                }
              });
            }
            resolve();
          })
        );
      });
    },
    getSiteChanges({ dateFrom, dateUntil }) {
      if (!this.includeSiteChanges || !this.task.siteId) {
        return Promise.resolve();
      }
      this.loadingSiteChanges = true;

      return this.$store
        .dispatch("sites/getChanges", {
          siteId: this.task.siteId,
          dateFrom,
          dateUntil
        })
        .finally(() => {
          this.loadingSiteChanges = false;
        });
    },
    getSiteChangesPaginated() {
      if (!this.task.siteId || !this.paginateSiteChanges) {
        return Promise.resolve();
      }
      this.pagination.loading = true;
      return this.$store
        .dispatch("sites/getChangesPaginated", {
          siteId: this.task.siteId,
          limit: this.pagination.limit,
          cursor: this.oldestSiteChange,
          dateFrom: this.task.dateCreated
        })
        .then(({ results, complete }) => {
          this.pagination.complete = complete;
          this.oldestSiteChange = this.$_.last(results);
        })
        .catch(() => {
          this.$toast.open({
            message: "Error retrieving changes",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },
    observeSiteChanges({ dateFrom, dateUntil }) {
      if (!this.includeSiteChanges) return;
      if (!this.task.siteId) return;
      if (this.observing.siteChanges) return;
      this.$set(this.observing, "siteChanges", this.unobserveSiteChanges);
      return this.$store.dispatch("sites/observeChanges", {
        siteId: this.task.siteId,
        dateFrom,
        dateUntil
      });
    },
    unobserveSiteChanges() {
      if (this.observing.siteChanges) {
        this.$delete(this.observing, "siteChanges");
        return this.$store.dispatch("sites/unobserveChanges", {
          siteId: this.task.siteId
        });
      }
    }
  }
};
</script>
